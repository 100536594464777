import React from 'react';
import {
  Actions,
  Block,
  Button,
  Description,
  Half,
  Hero,
  Notice,
  Layout,
  Title,
  Illustration,
} from '@dragonchain-dev/dragon-kit';
import { Secure } from '@dragonchain-dev/dragon-kit-icons';
import {
  CodeExamples,
  CodeIcons,
  Layout as PageLayout,
  SEO,
  HowDragonchainWorks,
} from '../../components/_index';

const { IMAGES_URI } = process.env;
const { ACCOUNT_URI } = process.env;

const Developers = () => {
  return (
    <PageLayout>
      <SEO
        title="Blockchain Developers | Dragonchain SaaS"
        description="New to blockchain? Learn how to leverage Bitcoin, Ethereum, Ethereum Classic, and BiananceChain to secure your data"
        image={`${IMAGES_URI}/banners/developers.png`}
        cardType="summary_large_image"
      />
      <Layout dark background="blue-dark-fade">
        <Hero
          type="code"
          illustration={`${IMAGES_URI}/illustrations/developer-hero-d@2x.png`}
          illustrationClass="up-50"
        >
          <Title color="white">A flexible API along with official SDKs</Title>
          <Description color="blue-light">
            <p>
              Dragonchain offers a restful API allowing you to easily read and write to your own blockchain.
            </p>
            <CodeIcons />
            <Actions
              actions={[
                {
                  type: 'link',
                  name: 'Documentation',
                  color: 'blue',
                  hover: 'blue-dark',
                  to: 'https://docs.dragonchain.com/',
                  blank: true,
                },
              ]}
            />
          </Description>
        </Hero>
        <Block>
          <br />
          <br />
          <Title center weight="300" color="blue-light">
            Be productive with lightweight SDKs
          </Title>
          <CodeExamples />
          <Notice>
            <p>Let us manage the blockchain infrastructure for you. Starting at $99 per month.</p>
            <Button blank type="link" color="blue" hover="blue-on-white" to={ACCOUNT_URI}>
              Sign Up
            </Button>
          </Notice>
        </Block>
      </Layout>
      <HowDragonchainWorks />
      <Layout dark background="blue-dark-fade">
        <Block center stack icon={Secure} iconColor="white">
          <Title size="large" color="blue-light" weight="300">
            Sign &amp; Publish Transactions
          </Title>
          <p>
            Invoking Ethereum smart contracts, even exchange of value transactions from Bitcoin to Ethereum
            can be completed from your node in a single API request. Already use Infura or run a parity node?
            Simply replace the built-in infrastructure with your own.
          </p>
          <Illustration src={`${IMAGES_URI}/illustrations/developer-blockchains@2x.png`} />
        </Block>
        <Block type="halves" divider>
          <Half
            center
            stack
            illustration={{
              src: `${IMAGES_URI}/illustrations/call-to-action-blockchain@2x.png`,
            }}
          >
            <Title>Create Your Blockchain</Title>
            <p>
              Writing to the ledger is immediate compared to others which take minutes or hours and may be
              slower under load performance.
            </p>
            <Button blank outline type="link" to={ACCOUNT_URI}>
              Get Started
            </Button>
          </Half>
          <Half
            center
            stack
            illustration={{
              src: `${IMAGES_URI}/illustrations/call-to-action-robot@2x.png`,
            }}
          >
            <Title>Strategic Bounty Projects</Title>
            <p>
              Help us grow and gain further adoption by taking up a strategic project. Bounties up to $17,500
              per project in Dragons are available.
            </p>
            <Button outline type="link" to="/developers/strategic-bounty-projects">
              Read More
            </Button>
          </Half>
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default Developers;
